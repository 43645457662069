import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ClassifiersService } from '@pgis/core/services/classifiers.service';
import { AuthenticationService } from '@pgis/core/services/authentication.service';
import { MapService } from '@pgis/core/services/map/map.service';
import { ClassifierModalComponent } from '@pgis/views/classifiers/classifier-modal/classifier-modal.component';
import { PrintModalComponent } from '@pgis/views/dashboard/print-modal/print-modal.component';
import { User, Classifier, PrintInfo, MapActions, LayerField } from '@pgis/shared/models';
import { PrintService } from './print.service';
import { ObjectFilter } from '@pgis/shared/models/object-filter.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ObjectLegendComponent } from './object-legend/object-legend.component';

@Component({
  selector: 'pgis-map-controls-tab',
  templateUrl: './map-controls-tab.component.html',
  styleUrls: ['./map-controls-tab.component.scss']
})
export class MapControlsTabComponent implements OnInit, OnDestroy {

  @Input()
  selectedObject: any;

  @Output()
  onFeatureDeselect: EventEmitter<number> = new EventEmitter<number>();

  @ViewChildren('objectLegend')
  objectLegend: QueryList<ObjectLegendComponent>;

  classifiers: Classifier[];
  currentCompanyId: number=107;
  currentObjectFilter: ObjectFilter;
  MapActions: typeof MapActions = MapActions;
  mapActions: MapActions;

  classifierToggleSubscription: Subscription;
  classifierIdToToggle: number | null;

  changedLayers = [];
  layerFields: string[];

  pointLabel: string;
  lineLabel: string;
  polygonLabel: string;
  selectLabel: string;
  printLabel: string;
  previousViewLabel: string;
  dragLabel: string;

  constructor(private mapService: MapService,
    private classifiersService: ClassifiersService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private printService: PrintService,
    private translateService: TranslateService) {
      this.translateService.onLangChange.subscribe(() => {
        this.setTranslationTooltips();
    });
    }

  ngOnInit() {
    this.setTranslationTooltips();
    this.classifierToggleSubscription = this.classifiersService.classifierToggled.subscribe((layerId: number) => {
      if (this.classifiers) { // classifiers already loaded so just toogle on layer
        this.toggleOnClassifier(layerId);
        return;
      }
      this.classifierIdToToggle = layerId;
    });
    this.loadLayers();
    const currentUserInfo: User = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUserInfo) {
      this.currentCompanyId = currentUserInfo.companyId;
    }
    this.mapService.geometryDrawn.subscribe(data => {
      this.loadLayers(this.currentObjectFilter);
    });
    this.mapService.selectedObject.subscribe(() => {  
      this.objectLegend.forEach(legend => {
          legend.closePopover();
      });
    });
  }

  private toggleOnClassifier(layerId: number) {
    const classifier = (<any>this.classifiers.map<Classifier[]>(c => c.childClassifiers)).flat().find(c => c.id === layerId);
    if (classifier) {
      classifier.checked = false;
      this.classifierClick(classifier);
    }
  }

  get getCurrentUser() {
    return this.authenticationService.getCurrentUser();
  }

  userHavePermission(permission: string) {
    return this.authenticationService.userHavePermission(permission);
  }

  get currentAction() {
    return this.mapService.currentAction;
  }

  get currentZoom() {
    return this.mapService.currentZoom;
  }

  loadLayers(objectFilter?: ObjectFilter) {
    this.currentObjectFilter = objectFilter;
    const selectedLayers: number[] = JSON.parse(localStorage.getItem('selectedLayers')) || [];
    let preselectedLayer;
    this.classifiersService.getMapLayerClassifiers(objectFilter).then(data => {
      this.classifiers = data;
      this.classifiers.forEach(c => {
        c.childClassifiers.forEach(cc => {
          if (selectedLayers.includes(cc.id) || cc.showDefault) {
            cc.checked = true;
            if (cc.showDefault) {
              selectedLayers.push(cc.id);
              preselectedLayer = cc;
            }
            if (cc.serverside) {
              this.mapService.toggleLayer(cc);
              const index = selectedLayers.indexOf(cc.id);
              if (index >= 0) {
                selectedLayers.splice(index, 1);
              }
            }
          }
        });
      });
      this.mapService.setFilters(selectedLayers, objectFilter);

      // if (preselectedLayer) {
      //   this.zoomToLayer(preselectedLayer);
      // }

      if (this.classifierIdToToggle) {
        this.toggleOnClassifier(this.classifierIdToToggle);
      }

      const uniqueFieldSet = new Set<string>((<any>this.classifiers.map<Classifier[]>(c => c.childClassifiers))
        .flat()
        .map((c: Classifier) => c.layerFields.map((layerField: LayerField) => layerField.name))
        .flat()
        .filter(fieldName => fieldName));

      this.layerFields = Array.from<string>(uniqueFieldSet);
      this.setSelectElement();
    });
  }

  classifierClick(classifier: Classifier) {
    classifier.checked = !classifier.checked;

    const selectedLayers: number[] = JSON.parse(localStorage.getItem('selectedLayers')) || [];
    if (classifier.checked) {
      selectedLayers.push(classifier.id);
    } else {
      const index = selectedLayers.indexOf(classifier.id);

      this.onFeatureDeselect.next();
      this.setSelectElement();

      selectedLayers.splice(index, 1);
    }
    localStorage.setItem('selectedLayers', JSON.stringify(selectedLayers));

    this.mapService.toggleLayer(classifier);
    if (!this.changedLayers.includes(classifier.id1)) {
      this.changedLayers.push(classifier.id1);
    }
  }

  visibleLayerCount(classifiers: Classifier[]): number {
    return classifiers.filter(c => c.checked).length;
  }

  isEmpty(objects) {
    if (!objects || objects.length === 0) {
      return true;
    }
    return false;
  }

  setDrawLine() {
    this.mapService.setDrawLine();
  }

  setDrawPoint() {
    this.mapService.setDrawPoint();
  }

  setDrawPolygon() {
    this.mapService.setDrawPolygon();
  }

  setSelectElement() {
    this.mapService.setSelectElement();
  }

  setDragElement() {
    this.mapService.setDragElement();
  }

  setMeassure(type: string) {
    this.mapService.setMessureTool(type);
  }

  toPreviousView() {
    this.mapService.toPreviousView();
  }

  editObject(classifier: Classifier) {
    this.classifiersService.getClassifier(classifier.id).then(data => {
      data.checked = classifier.checked;
      data.parentName = classifier.parentName;
      data.parentDescription = classifier.parentDescription;
      classifier = data;

      const modalRef = this.modalService.open(ClassifierModalComponent);
      modalRef.componentInstance.classifierId = classifier.id;
      modalRef.componentInstance.classifier = _.clone(classifier);
      modalRef.componentInstance.baseClassifierId = classifier.id1;
      modalRef.componentInstance.childClassifier = true;
      modalRef.componentInstance.modalWindow = (<any>modalRef)._windowCmptRef.instance; // pass modal window instance to be able to resize

      modalRef.result.then((result) => {
        this.classifiersService.updateClassifier(1, result).then(() => {
          const selectedLayers: number[] = JSON.parse(localStorage.getItem('selectedLayers')) || [];
          this.classifiersService.getMapLayerClassifiers().then(data => {
            this.classifiers = data;
            this.classifiers.forEach(c => {
              c.childClassifiers.forEach(cc => {
                if (selectedLayers.includes(cc.id)) {
                  cc.checked = true;
                  if (cc.serverside) {
                    this.mapService.toggleLayer(cc);
                    const index = selectedLayers.indexOf(cc.id);
                    if (index >= 0) {
                      selectedLayers.splice(index, 1);
                    }
                  }
                }
              });
            });
            //Map object refresh after style change
            if (result.checked) {
              result.checked = false;
              this.mapService.toggleLayer(result);
              result.checked = true;
              this.mapService.toggleLayer(result);
            }
            this.setSelectElement();
          });
        },
          (err) => {
            console.log('Error updating classifier!');
          });
      },
        (dismissReason) => {
        });
    });
  }

  zoomToLayer(classifier: Classifier) {
    this.mapService.zoomToLayer(classifier, this.currentObjectFilter);
  }

  printMap() {
    this.modalService.open(PrintModalComponent).result.then((printInfo: PrintInfo) => {
      this.mapService.map.once('postcompose',
        event => {
          const canvas = event.context.canvas;
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(canvas.msToBlob(), 'map.png');
          } else {

            const featureIds: number[] = printInfo.includeDataTables
              ? this.mapService.getVisibleFeatures().map(f => f.getProperties().id)
              : [];
            const arr = [];
            this.classifiers.forEach(c => {
              c.childClassifiers.forEach(cc => {
                if (cc.checked) {
                  arr.push(cc);
                }
              });
            });
            this.printService.getPrintoutContent(printInfo, this.currentCompanyId, featureIds, canvas, arr).then(printoutContent => {
              const popupWin = window.open();
              popupWin.document.open();
              popupWin.document.write(printoutContent);
              popupWin.document.close();
            });

          }
        });
      this.mapService.map.renderSync();
    },
      (reason) => {
        console.log(reason);
      });
  }

  ngOnDestroy(): void {
    this.classifierToggleSubscription.unsubscribe();
  }

  setTranslationTooltips() {
    this.pointLabel = this.translateService.instant('MAP.POINT');
    this.lineLabel = this.translateService.instant('MAP.LINE');
    this.polygonLabel = this.translateService.instant('MAP.POLYGON');
    this.selectLabel = this.translateService.instant('MAP.SELECT');
    this.printLabel = this.translateService.instant('MAP.PRINT');
    this.previousViewLabel = this.translateService.instant('MAP.PREVIOUS_VIEW');
    this.dragLabel = this.translateService.instant('MAP.DRAG_OBJECT');
  }
}
